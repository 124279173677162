import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

function OurPromise() {
  const listPoints = [
    "Individual focus and increased in-class participation",
    "In-depth understanding of concepts",
    "Instant and personalized clarification of each doubt",
    "Chapter-wise tests to make your child exam ready",
    "Concrete feedback on child's progress",
    "Boosts your child’s confidence",
  ]
  return (
    <div className="mx-md-4 mx-0 mb-4 my-5">
      <div className="container">
        <div className="download_app_heading font-samsung text-center">
          <p className="color-black">
            Our &nbsp;
            <span className="color-yellow">Promise</span>
          </p>
        </div>
        <div className="row">
          <div className="col-xs-12 col-lg-6">
            <div className="mx-auto text-center mt-lg-n5">
              <StaticImage
                src="../../images/studilive/promise.svg"
                layout="constrained"
                placeholder="tracedSVG"
                alt="What makes us Unique"
                title="What makes us Unique"
                loading="lazy"
                quality="100"
                style={{ width: "70%" }}
                // formats={["auto"]}
              />
            </div>
          </div>
          <div className="col-lg-6 col-xs-12 px-2 ">
            <div
              className="app-description font-lato"
              style={{ textAlign: "initial" }}
            >
              <ul style={{ display: "table", margin: "0 auto" }}>
                {listPoints.map((item, key) => {
                  return (
                    <li>
                      <p>{item}</p>
                    </li>
                  )
                })}
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div className="text-center">
        <Link to="https://liveadmin.tatastudi.com/registration">
          {" "}
          <button className="studi-btn book-demo">
            Book a Demo Class
          </button>{" "}
        </Link>
      </div>
    </div>
  )
}

export default OurPromise
