import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "../../css/studilive/whyStudilive.css"

function WhyStudilive(props) {
  const drupalData = useStaticQuery(graphql`
    query whyStudiLive {
      allNodeWhyStudilive {
        nodes {
          field_heading
          field_description {
            processed
          }
          relationships {
            field_images {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = drupalData.allNodeWhyStudilive.nodes

  const [index, setIndex] = useState(-1)

  const cardExpand = val => {
    //CONDITIONAL TO HANDLE PLUS MINUS AND EXPAND COLLAPSE POSITION CHANGE
    if (index == val) {
      setIndex(-1)
      document.getElementById("card" + val).style.position = "static"
    } else {
      setIndex(val)
      document.getElementById("card" + val).style.position = "absolute"
    }

    document.getElementById("card" + val).style.zIndex = 20

    /*To prevent all cards from getting changed into absolute position and same z-index at the same time*/

    var allVal = [1, 2, 3, 4]
    // ALL POSSIBLE VALUES OF i THAT ARE GENERATED SINCE WE HAVE 4 CARDS

    allVal.forEach(item => {
      if (item != val) {
        document.getElementById("card" + item).style.zIndex = 1
        document.getElementById("card" + item).style.position = "static"
      }
    })
  }



  return (
    <div className="section-gap" id="why_studi_live">
        {/* <div className="download_app_heading font-samsung text-center">
          <p className="color-black mb-0">
            Why &nbsp;
            <span className="color-yellow">StudiLive</span>
          </p>
        </div> */}
      <div className="container">
        <div className="row mt-lg-n4" id="studi-live-cards">
          {data.map((item, i) => {
            i = i + 1
            return (
              <div
                className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center mb-4 my-lg-1"
                key={i}
                style={{ position: "relative" }}
              >
                <div
                  className="studi-live-card text-center py-2"
                  id={"card" + i}
                >
                  <GatsbyImage
                    image={
                      item.relationships.field_images.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    className="exp-card-logo mt-1"
                    alt="Why StudiLive"
                    title="Why StudiLive"
                    loading="lazy"
                    placeholder="tracedSVG"
                    quality="50"
                    formats={["webp"]}
                  />
                  <div className="studi-live-heading font-lato">
                    {item.field_heading}
                  </div>
                  <div className="plus-parent text-center">
                    <i
                      className={
                        index == i
                          ? "fa fa-minus-circle plus-icon"
                          : "fa fa-plus-circle plus-icon"
                      }
                      data-toggle="collapse"
                      href={"#collapse" + i}
                      aria-expanded="false"
                      aria-controls={"collapse" + i}
                      onClick={() => cardExpand(i)}
                    />
                    <p className="plus-line mx-auto" />
                  </div>

                  <div
                    className="collapse multi-collapse font-lato studi-live-desc p-2"
                    id={"collapse" + i}
                    data-parent="#studi-live-cards"
                    dangerouslySetInnerHTML={{
                      __html: item.field_description.processed,
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>

        {/* <div className="font-lato mt-5">
          <p className="text-center" style={{ fontSize: "24px" }}>
            <b>Our promise</b>
          </p>

          <div className="row">
            <div className="col-12 col-md-8">
              <ul style={{ display: "table", margin: "0 auto" }}>
                {listPoints.map((item, key) => {
                  return <li>{item}</li>
                })}
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default WhyStudilive
