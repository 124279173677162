import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "../../css/newhomebanner.css"

function showBanners(value) {
  if (value) {
    return (
      <div className="carousel-inner">
        <Link to="https://liveadmin.tatastudi.com/registration">
          <div className="carousel-item active">
            <div className="d-block">
              <StaticImage
                src="../../images/studilive/Web-Banner-Mobile.jpg"
                layout="fullWidth"
                placeholder="tracedSVG"
                loading="eager"
                alt="Tata Studi Banner"
                title="Tata Studi Banner"
                quality="50"
              />
              <button
                id="studilivebtn"
                className="btn trial_button desktop_btn"
              >
                <div className="trial_text">
                  Book a Demo Class &nbsp;
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </div>
              </button>
            </div>
          </div>
        </Link>
      </div>
    )
  } else {
    return (
      <div className="carousel-inner">
        <Link to="https://liveadmin.tatastudi.com/registration">
          <div className="carousel-item active">
            <div className="d-block desktop-banner mt-n3">
              <StaticImage
                src="../../images/studilive/Web-Banner-Home-page-02.jpg"
                layout="fullWidth"
                placeholder="tracedSVG"
                loading="eager"
                alt="StudiLive Banner"
                title="StudiLive Banner"
                aspectRatio={21 / 7}
                quality="100"
              />

              <button
                id="studilivebtn"
                className="btn trial_button desktop_btn"
              >
                <div className="trial_text">
                  Book a Demo Class &nbsp;
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </div>
              </button>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

function StudiLiveBanner(props) {
  return (
    <div>
      <div className="banner-wrapper" id="wrap-banner">
        <div
          id="carouselControl"
          className="carousel slide"
          data-ride="carousel"
        >
          {showBanners(props.mobileScreen)}
          {/* <a
            className="carousel-control-prev"
            href="#carouselControl"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselControl"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a> */}
        </div>
        <div className="bottom-wrapper">
          <div className="bottom_text_card">
            <span className="bottom_text">#PadhneKaSahiTareeka</span>
          </div>

          <div className="yellow_border" />
        </div>
      </div>
    </div>
  )
}

export default StudiLiveBanner
