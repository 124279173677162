import React from "react"
import AndroidStoreImg from "../../images/playstore.png"
import AppStoreImg from "../../images/appstore.png"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

const listPoints = [
  "Small group classes",
  "Well planned learning",
  "Free access to Tata Studi learning application",
  "1-to-1 free live doubt clarification sessions",
  "Unique performance monitoring system",
]
class MakesUnique extends React.Component {
  propTypes = {
    data: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div className="mx-md-4 mx-0 mb-4" id="uniquefeatures">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xs-12 px-2 ">
              <div className="download_app_heading font-samsung color-black text-center">
                <p className="color-black">
                  What make us &nbsp;
                  <span className="color-yellow">Unique</span>
                </p>
              </div>

              <div
                className="app-description font-lato"
                style={{ textAlign: "initial" }}
              >
                <ul style={{ display: "table", margin: "0 auto" }}>
                  {listPoints.map((item, key) => {
                    return (
                      <li>
                        <p>{item}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>

              {/* <div className="store-links text-center beside_image">
                <h5>
                  <span className="font-weight-bold">Download Now!</span>
                </h5>

                <a
                  href="https://play.google.com/store/apps/details?id=com.tce.studi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AndroidStoreImg}
                    alt="androidlogo"
                    title="Android App"
                    className="da_app_store_img play_store"
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/tata-studi/id1532854969"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppStoreImg}
                    alt="appstorelogo"
                    title="IOS App"
                    className="da_app_store_img"
                  />
                </a>
              </div> */}
            </div>

            <div className="col-xs-12 col-lg-6">
              <div className="mx-lg-1 mx-auto text-center">
                <StaticImage
                  src="../../images/studilive/unique1.svg"
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="What makes us Unique"
                  title="What makes us Unique"
                  loading="lazy"
                  quality="100"
                  style={{ width: "70%" }}
                  // formats={["auto"]}
                />
              </div>
            </div>

            {/* <div className="da_margin_twenty text-center justify-content-center below_image">
              <h5>
                <b>Download Now!</b>
              </h5>
              <a
                href="https://play.google.com/store/apps/details?id=com.tce.studi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AndroidStoreImg}
                  alt="androidlogo"
                  title="Android App"
                  className="da_app_store_img play_store"
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/tata-studi/id1532854969"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AppStoreImg}
                  alt="appstorelogo"
                  title="IOS App"
                  className="da_app_store_img"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default MakesUnique
