/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from "react"
import SEO from "../../components/seo"
import useIsMobile from "../../hooks/useIsMobile"

import HowItWorks from "../../components/StudiLive Comps/howItWorks"
import FormHome from "../../components/StudiLive Comps/formHome"
import WhyStudilive from "../../components/StudiLive Comps/whyStudilive"
import Navbar from "../../components/StudiLive Comps/navbar"
import StudiLiveBanner from "../../components/StudiLive Comps/studiLiveBanner"
import StudiLiveFooter from "../../components/StudiLive Comps/footer"
import StudiLiveMobileFooter from "../../components/StudiLive Comps/mobileFooter"
import MobileNavbar from "../../components/StudiLive Comps/mobileNavbar"
import MakesUnique from "../../components/StudiLive Comps/whatMakesUnique"

import "../../css/studilive/globalRules.css"
import "../../css/globals.css"
import OurPromise from "../../components/StudiLive Comps/ourPromise"
import DatalayerEvents from "../../../services/datalayer"

export default function Index() {
  const isMobile = useIsMobile('1023px');

  const [datalayerEvents, setdatalayerEvents] = useState(new DatalayerEvents())

  useEffect(() => {
    datalayerEvents.init();
  }, [])

  return (
    <div>
      <SEO
        title="Studi Live | Tata Studi"
        lang="en"
      />
      {isMobile ? <MobileNavbar /> : <Navbar />}
      <StudiLiveBanner mobileScreen={isMobile}/>
      <WhyStudilive />
      <OurPromise/>
      <HowItWorks />
      <MakesUnique />
      <FormHome />
      {isMobile ? <StudiLiveMobileFooter /> : <StudiLiveFooter />}
    </div>
  )
}
