import { Link } from "gatsby"
import React from "react"
import ReactPlayer from "react-player/lazy"
import { StaticImage } from "gatsby-plugin-image"

function HowItWorks() {
  const data = [
    {
      title: "Pre-Class",
      desc:
        "Our unique Big Idea concept helps student to get an overview of a chapter.",
    },
    {
      title: "In Class",
      desc:
        "Learn from our certified tutors, Practice in-class and get Summary to revise the concept.",
    },
    {
      title: "Post Class",
      desc:
        "Study notes, assignments for Retrieval practice and access to Tata Studi application.",
    },
    {
      title: "Evaluation",
      desc:
        "Unique performance monitoring system along with weekly & monthly progress report and monthly Parent-Teacher meetings",
    },
  ]
  return (
    <div className="section-gap" id="how_it_works">
      <div className="download_app_heading font-samsung text-center">
        <p className="color-black mb-0">
          How &nbsp;
          <span className="color-yellow">It Works</span>
        </p>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 mx-auto">
            <div className="player-wrapper rounded-lg">
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/embed/2vk7x95Jkgk"
                width="100%"
                height="100%"
                controls
                playing
                light="https://i.ytimg.com/vi/zxdqjMjvkL8/maxresdefault.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row my-4 px-1 py-2">
          <div className="col-10 col-md-6 col-lg-3 my-1">
            <StaticImage
              src="../../images/studilive/Pre-Class.svg"
              layout="fullWidth"
              placeholder="tracedSVG"
              alt="How It Works"
              title="How It Works"
              loading="lazy"
              quality="60"
              className="how-it-works-image mx-auto"
            />
            <div className="font-lato">
              <b>
                <div className="text-center" style={{ fontSize: "18px" }}>
                  Pre-Class
                </div>
              </b>
              <div className="content-how-it-works">
                Our unique <b>Big Idea</b> concept help students in order to get
                an overview of a chapter in the form of effective and meaningful
                videos.
              </div>
            </div>
          </div>

          <div className="col-10 col-md-6 col-lg-3 my-1">
            <StaticImage
              src="../../images/studilive/In class.svg"
              layout="fullWidth"
              placeholder="tracedSVG"
              alt="How It Works"
              title="How It Works"
              loading="lazy"
              quality="60"
              className="how-it-works-image mx-auto"
            />
            <div className="font-lato">
              <b>
                <div className="text-center" style={{ fontSize: "18px" }}>
                  In Class
                </div>
              </b>
              <div className="content-how-it-works">
                <b>Learn</b> from our certified tutors, <b>Practice</b> in-class
                and get <b>Summary </b>
                to revise the concept.
              </div>
            </div>
          </div>

          <div className="col-10 col-md-6 col-lg-3 my-1">
            <StaticImage
              src="../../images/studilive/Post Class.svg"
              layout="fullWidth"
              placeholder="tracedSVG"
              alt="How It Works"
              title="How It Works"
              loading="lazy"
              quality="60"
              className="how-it-works-image mx-auto"
            />
            <div className="font-lato">
              <b>
                <div className="text-center" style={{ fontSize: "18px" }}>
                  Post Class
                </div>
              </b>
              <div className="content-how-it-works">
                <b>Study notes</b>, assignments for <b>Retrieval practice </b>{" "}
                and access to <b>Tata Studi</b> application.
              </div>
            </div>
          </div>

          <div className="col-10 col-md-6 col-lg-3 my-1">
            <StaticImage
              src="../../images/studilive/Evaluation.svg"
              layout="fullWidth"
              placeholder="tracedSVG"
              alt="How It Works"
              title="How It Works"
              loading="lazy"
              quality="60"
              className="how-it-works-image mx-auto"
            />
            <div className="font-lato">
              <b>
                <div className="text-center" style={{ fontSize: "18px" }}>
                  Evaluation
                </div>
              </b>
              <div className="content-how-it-works">
                Unique <b>performance monitoring</b> system along with 
                <b> progress report</b> and {" "}
                <b>Parent-Teacher meetings</b>.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Link to="https://liveadmin.tatastudi.com/registration">
          {" "}
          <button className="studi-btn book-demo">
            Book a Demo Class
          </button>{" "}
        </Link>
      </div>
    </div>
  )
}

export default HowItWorks
